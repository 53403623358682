import React from 'react';
import banner from "../img/aplicaciones-robotica-sector-agroalimentario-140923.jpg";
import footer from "../img/footer.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="Aplicaciones de la robótica e inteligencia artificial en el sector agroalimentario" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-warning centrado" role="alert">
						<h5>Aplicaciones de la robótica e inteligencia artificial en el sector agroalimentario</h5>
						<h6>FORO NACIONAL DE APLICACIONES DE  ROBÓTICA E INTELIGENCIA ARTIFICIAL EN EL SECTOR AGROALIMENTARIO, evento organizado por el Instituto para la Competitividad Empresarial de Castilla y León (ICE) y El Centro Tecnológico Agrario y Agroalimentario ITAGRA.CT, en el marco del Programa de Capacitación y Apoyo a la I+D+i Empresarial “Centr@Tec,”.</h6>
						<h7><strong>Fecha y horario:</strong> 14 de septiembre de 2023, de 9:00 a 18:00</h7><br></br>
						<h7><strong>Lugar:</strong> Centro Integrado de Formación Profesional "Viñalta" (Palencia)</h7>
						<hr></hr>
						<strong>Objetivos del proyecto:</strong>
						<p>El objetivo del foro es dar a conocer las aplicaciones de estás tecnologías en unos sectores fundamentales a nivel nacional como son el agroalimentario e industrial, para poner en valor nuevas oportunidades de negocio y emprendimiento, atracción de talento, y promoción de las líneas de investigación.</p>
					</div>
				</div>
			</div>
			<div className="card text-black bg-warning w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p>
        			</a>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda:</h5>
						<li><strong>08:30 - 09:00 Recepción. Entrega acreditaciones</strong><br></br>
						</li>
						<li><strong>09:00 - 09:15 Apertura del foro</strong><br></br>
							<i>- José Manuel Turzo Ruiz - Director Centro de Formación Agraria de Viñalta</i><br></br>
							<i>- Augusto Cobos Pérez - Director General del Instituto de Competitividad Empresarial (ICE JCyL)</i><br></br>
							<i>- Asier Saiz Rojo - Director de Itagra.CT</i>
						</li>
						<li><strong>09:15 - 10:00 Aplicaciones robóticas en la agricultura actual: Cultivando con datos</strong><br></br>
							<i>Dionisio Andújar Sánchez - Centro de Automática y Robótica. Grupo de Percepción Artificial (CAR CSIC).</i>
						</li>
						<li><strong>10:00 - 10:30 Normativa de seguridad en la maquinaria agrícola</strong><br></br>
							<i>Emilio Allué - Director Técnico en ANSEMAT (Asociación Nacional de Maquinaria Agropecuaria y Espacios Verdes)</i>
						</li>
						<li><strong>10:30 - 11:00 Financiación para la incorporación de las tecnologías a la agricultura</strong><br></br>
							<i>Consejería de Agricultura</i>
						</li>
						<li><strong>11:00 - 11:30 Pausa café</strong></li>
						<li><strong>11:30 - 13:30 Casos reales de aplicación de robótica, inteligencia artificial</strong><br></br>
							<i>- Rubén Arce Santolaya - Director de Transformación Digital y TIC en Grupo Matarromera</i><br></br>
							<i>- David Frances – CEO de Agerpix</i><br></br>
							<i>- Sophie Graillot - Referente técnico en  Naïo Technologies</i><br></br>
							<i>- Josep Dalmases  - Responsable del departamento de innovación en AG-Group</i><br></br>
							<i>- Olena Dobrovolska - Responsable Marketing y comunicación en Meropy</i>
						</li>
						<li><strong>13:30 - 14:30 Mesa redonda</strong><br></br>
						<i>- Brecha tecnológica y problemática actuales</i><br></br>
						<i>- Capacidades de I+D+i y formación</i><br></br>
						<i>- Potenciales usuarios. Oportunidades de negocio para el sector agroalimentario e industrial de Castilla y León</i><br></br>
						<i>- Normativa asociada a la Agricultura 4.0</i><br></br>
						<i>Modera:</i><br></br>
						<i>Eduardo Andrés Martínez Director de NODDO - Red Centros Tecnológicos CyL</i><br></br>
						<i>Participan:</i><br></br>
						<i>Cristina Ramírez, Directora de Vitartis. Dionisio Andújar Sánchez, Grupo de Percepción Artificial (CAR CSIC).</i><br></br>
						<i>Emilio Allué, Director Técnico en ANSEMAT</i><br></br>
						<i>Miguel Hernando, Profesor en Centro de Formación Agraria de Viñalta.</i><br></br>
						<i>Julio Martín, Técnico de I+D+i en Itagra.</i>
						</li>
						<li><strong>14:30 - 15:30 Comida (vino español)</strong><br></br>
							<i>Tanto la comida como el desayuno están incluidos en la asistencia gratuita</i>
						</li>
						<li><strong>15:30 - 18:00 Demostración en campo con robots y máquinas equipadas con sistemas de IA</strong><br></br>
							<i><strong>OnFruit 360 – ARGEPRIX</strong></i><br></br>
							<i>Sistema automático de conteo de fruta sobre el árbol para conocer la cantidad de fruto en plantaciones con una precisión del 90-95%. Con estos datos de valor se optimizan todos los procesos de las empresas.</i><br></br>
							<i><strong>OZ – Naïo Technologies</strong></i><br></br>
							<i>Robot agrícola idóneo para invernaderos o pequeños huertos ecológicos. Permite desherbar, hacer surcos y sembrar entre otras funcione.  Funciona de forma totalmente autónoma gracias a su sistema de guiado GPS RTK, reduciendo las pérdidas de producción y, por lo tanto, ayudando a asegurar la cosecha.</i><br></br>
							<i><strong>SentiV – MEROPY</strong></i><br></br>
							<i>Robot autónomo que vigila e inspecciona los cultivos rodando por los campos. Está diseñado para detectar problemas en los cultivos, malas hierbas, plagas o enfermedades, mientras recorre los campos de forma autónoma.se mueve sobre ruedas de radios abiertos sin llanta.</i><br></br>
							</li>
						<li><strong>18:00 - 18:15 Clausura</strong>
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="card text-white bg-secondary border-secondary w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title">Fecha y horario:</h5>
					<p className="card-text-left"> 14 de septiembre de 2023, de 9:00 a 18:00</p>
					<p className="card-text-left"> Centro Integrado de Formación Profesional "Viñalta" (Palencia)</p>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/790" width="95%" height="900px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<img src={footer} className="banner" alt="Aplicaciones de la robótica e inteligencia artificial en el sector agroalimentario" />
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					Más información <i class="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp;
					<i class="fas fa-paper-plane"></i>&nbsp;info@itagra.com
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					<a href="https://www.itagra.com">Itagra CT</a>
				</div>
			</div>
		</div>
)

export default Home